import _ from "lodash";

/**
 * 判断是否滚动条是否到最底部
 * @param lowerThreshold
 * @param callBack
 */
export const listenScrollToBottom = ({ ele = "", callBack = () => [] }) => {
  setTimeout(() => {
    const e = document.querySelector(ele);
    e && e.addEventListener("scroll", _.debounce(callBack(e), 1000));
  }, 500);
};

export const calcScroll =
  ({ ele = document.documentElement, callBack = () => [] }) =>
  () => {
    const a = ele.clientHeight;
    const b = ele.scrollTop;
    const c = ele.scrollHeight;
    if (a + b >= c - 10 && b !== 0) {
      callBack();
    }
  };
